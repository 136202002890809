import { graphql } from 'gatsby'
import React from 'react'
import { BlogTagItemsQuery } from '../../types/graphql-types'
import { IfAwesomePagination } from '../../types/utils'
import StructuredData from '../const/structuredData'
import { useSiteMetaData } from '../hooks/useSiteMetaData'
import DefaultLayout from '../layouts/DefaultLayout'
import HeadMeta from '../components/organisms/HeadMeta'
import TagHeadline from '../components/atoms/tag-headline/TagHeadline'
import ItemList from '../components/organisms/item-list/ItemList'
import Pager from '../components/molecules/pager/Pager'
import { archiveTitle, joinSpace } from '../libs/utils'

interface IfPops {
  data: BlogTagItemsQuery
  pageContext: IfAwesomePagination
}

const BlogTagPage: React.FC<IfPops> = ({ data, pageContext }) => {
  const {
    name: tagName,
    uri: tagUri,
    link: tagLink,
    description: tagDescription
  } = data.wpTag as {
    name: string
    uri: string
    link: string
    description?: string
  }

  // StructuredData
  const sd$ = new StructuredData(useSiteMetaData())

  return (
    <DefaultLayout>
      {' '}
      <HeadMeta
        ogType="website"
        title={archiveTitle(tagName, pageContext.numberOfPages)}
        description={tagDescription}
        structuredDataBreadcrumbs={sd$.breadcrumbs([
          { id: tagLink, name: tagName }
        ])}
        structuredDataArchive={sd$.archive(tagLink, tagName)}
      />{' '}
      <TagHeadline
        tag="h2"
        copy={`# ${tagName}`}
        classes={['typography-subhead']}
      />{' '}
      <ItemList items={data.allWpPost} />{' '}
      {pageContext.numberOfPages > 1 && (
        <div className={joinSpace(['apps-container', 'pt-6', 'pb-6'])}>
          <Pager items={pageContext} prefix={tagUri} />
        </div>
      )}
    </DefaultLayout>
  )
}

export default BlogTagPage

export const query = graphql`
  query BlogTagItems($fieldValue: String!, $skip: Int!, $limit: Int!) {
    wpTag(uri: { eq: $fieldValue }) {
      name
      description
      uri
      link
    }
    allWpPost(
      skip: $skip
      limit: $limit
      filter: {
        status: { eq: "publish" }
        tags: { nodes: { elemMatch: { uri: { eq: $fieldValue } } } }
      }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          ...flagPostDefault
          excerpt
        }
      }
    }
  }
`
